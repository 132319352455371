import {
  Card,
  FlexBox,
  Typography,
} from '@vp/swan'
import { useSwanStyleKeys, useUserContext, useUserContextTracker } from '@vp/ubik-context'

export type Props = {
  /**/
}

function App (props: Props) {
  useSwanStyleKeys(['core'])
  import('./foo.ts').then(({ default: foo }) => console.log(foo))
  console.log('Message from App.tsx')

  const context = useUserContext()

  const tracker = useUserContextTracker()

  const featureFlags = context.featureFlags

  console.log('context: ', context)

  for (const featureFlag of Object.keys(featureFlags ?? {})) {
    tracker({
      type: 'featureFlag',
      name: featureFlag,
      enabled: featureFlags?.[featureFlag] ?? 'false',
    })
  }

  return (
    <FlexBox>
      <Card style={{ width: '100%' }}>
        <Typography textAllCaps fontWeight='bold' mb={1} mr={1}>
          Hello World from test-fragment fragment! And some more text! We are testing slack handler... Locale: {context.locale}, Tenant: {context.tenant}, ProductId: {context.productId}
        </Typography>
      </Card>
    </FlexBox>
  )
}

export default App
